import { Typography, useTheme } from "@mui/material";
import dollarFormatter from "utils/FormatterDollar";
import CTrackerTimelineItem from "components/CTimelineItem";
import useTracker from "components/CTracker/useTrackerContext";
import { useEffect, useState } from "react";
import { PrivateLabelFees } from "typedef/PrivateLabelFees";
import { ClosingCostDetails } from "screens/FinalOffer/useHelocOffer";
import API from "utils/API";

const OfferInformation: React.FC = () => {
  const theme = useTheme();
  const { loan } = useTracker();
  const initialOffer = loan?.initialOffer;
  const finalOffer = loan?.finalOffer;
  const offerHasError = finalOffer?.error || initialOffer?.error;
  const errorMessage = offerHasError
    ? initialOffer?.message || finalOffer?.message
    : "";

  const [fees, setFees] = useState<PrivateLabelFees & ClosingCostDetails>();
  const state = loan?.property?.address.components.state_abbreviation;

  useEffect(() => {
    if (loan?.id) {
      const fetchFees = async () => {
        if (loan) {
          const response = await API.get<PrivateLabelFees & ClosingCostDetails>(
            `/getServiceUnsecured/origination-fees?state=${state}&loanId=${loan?.id}`,
          );
          if (!("error" in response)) {
            setFees(response.data);
          }
        }
      };
      fetchFees();
    }
  }, [loan, state]);

  const totalPayoffsAndPaydowns = finalOffer
    ? fees?.totalPayoffsAndPaydowns ?? 0
    : 0;

  const offer = finalOffer ? finalOffer : initialOffer;

  const initalDrawToUse = finalOffer
    ? finalOffer?.initialDrawAmount
    : initialOffer?.initialDrawAmount ?? 0;

  const isInitalDrawSameToLoanAmount =
    offer?.initialDrawAmount === offer?.amount;

  const initialDrawAmount = isInitalDrawSameToLoanAmount
    ? offer?.amount
    : initalDrawToUse + (fees?.totalFees ?? 0) + totalPayoffsAndPaydowns;

  return (
    <CTrackerTimelineItem
      status={offerHasError ? "failed" : "success"}
      title="Offer"
      tooltip="Your offer has been calculated using your credit, loan balance, value, and requested loan amount. See FAQ’s for additional detail."
    >
      {initialOffer && !finalOffer && !offerHasError && (
        <>
          <Typography
            variant="h6"
            data-cy="loanAmountOffer"
            color={theme.palette.primary.main}
          >
            {loan.loanType === "HELOAN"
              ? `$${(initialOffer.amount ?? 0).toLocaleString(
                  "en-US",
                )} Loan with an interest rate of ${(
                  (initialOffer.interestRate ?? 0) * 100
                ).toFixed(2)}% and an APR of ${(
                  Math.round((initialOffer.APR ?? 0) * 10000) / 100
                ).toFixed(2)}%`
              : `$${(initialOffer.amount ?? 0).toLocaleString(
                  "en-US",
                )} Line of credit with an initial interest rate of ${(
                  (initialOffer.interestRate ?? 0) * 100
                ).toFixed(2)}%`}
          </Typography>
          {loan.property.address.components.state_abbreviation === "TN" ? (
            <Typography variant="h6" color={theme.palette.text.primary}>
              {loan.loanType === "HELOAN"
                ? `15 year term, (payments of ${dollarFormatter.format(
                    initialOffer.monthlyPayment ?? 0,
                  )})`
                : ` Initial draw amount of $${initialDrawAmount?.toLocaleString(
                    "en-US",
                  )}
                  5 year interest only draw period followed by 10 year fully amortized repayment period`}
            </Typography>
          ) : (
            <Typography variant="h6" color={theme.palette.text.primary}>
              {loan.loanType === "HELOAN"
                ? `25 year term, (payments of ${dollarFormatter.format(
                    initialOffer.monthlyPayment ?? 0,
                  )})`
                : ` Initial draw amount of $${initialDrawAmount?.toLocaleString(
                    "en-US",
                  )}
                  5 year interest only draw period followed by 25 year fully amortized repayment period`}
            </Typography>
          )}
        </>
      )}
      {initialOffer && finalOffer && (
        <>
          <Typography
            variant="h6"
            data-cy="loanAmountOffer"
            color={theme.palette.primary.main}
          >
            {loan.loanType === "HELOAN"
              ? `$${(finalOffer.amount ?? 0).toLocaleString(
                  "en-US",
                )} Loan with an interest rate of ${(
                  (finalOffer.interestRate ?? 0) * 100
                ).toFixed(2)}% and an APR of ${(
                  Math.round((finalOffer.APR ?? 0) * 10000) / 100
                ).toFixed(2)}%`
              : `$${(finalOffer.amount ?? 0).toLocaleString(
                  "en-US",
                )} Line of credit with an initial interest rate of ${(
                  (finalOffer.interestRate ?? 0) * 100
                ).toFixed(2)}%`}
          </Typography>
          {loan.property.address.components.state_abbreviation === "TN" ? (
            <Typography variant="h6" color={theme.palette.text.primary}>
              {loan.loanType === "HELOAN"
                ? `15 year term, (payments of ${dollarFormatter.format(
                    finalOffer?.monthlyPayment ?? 0,
                  )})`
                : ` Initial draw amount of $${initialDrawAmount?.toLocaleString(
                    "en-US",
                  )}
                  5 year interest only draw period followed by 10 year fully amortized repayment period`}
            </Typography>
          ) : (
            <Typography variant="h6" color={theme.palette.text.primary}>
              {loan.loanType === "HELOAN"
                ? `25 year term, (payments of ${dollarFormatter.format(
                    finalOffer?.monthlyPayment ?? 0,
                  )})`
                : ` Initial draw amount of $${initialDrawAmount?.toLocaleString(
                    "en-US",
                  )}
                  5 year interest only draw period followed by 25 year fully amortized repayment period`}
            </Typography>
          )}
        </>
      )}
      {offerHasError ? (
        <Typography variant="h6" color={theme.palette.text.primary}>
          {errorMessage}
        </Typography>
      ) : null}
    </CTrackerTimelineItem>
  );
};

export default OfferInformation;
