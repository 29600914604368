import { Box, Grid, Typography } from "@mui/material";
import { FormData } from "../../useGetStarted";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { CustomInput } from "../LoanRequest/CustomInput";
import NumberFormat from "react-number-format";
import usePersonalDataValidation from "./usePersonalDataValidation";
import CHBTextField from "components/CHBTextField";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import { useEffect, useRef, useState } from "react";
import { CountryPhoneCode } from "utils/enumCountryPhoneCode";

export interface BorrowerPersonalDataProps {
  onNext: () => void;
  onPrev: () => void;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

export const BorrowerPersonalData = ({
  onNext,
  onPrev,
  formData,
  setFormData,
}: BorrowerPersonalDataProps) => {
  const { primaryText, secondaryText, fourthText } = useHomeBridgeColors();
  const { sizes } = useHomebridgeSizes();
  const {
    error,
    errors,
    animation,
    handleNext,
    onValueChange,
    handleCheckboxChange,
    checkboxState,
    validateInputs,
    loading,
  } = usePersonalDataValidation(formData, setFormData, onNext);

  const [displayPhoneNumber, setDisplayPhoneNumber] = useState("");

  const [countryCode, setCountryCode] = useState("+1");

  useEffect(() => {
    if (formData.phoneNumber && !loadedPhoneNumber.current) {
      const numberWithoutCode = formData.phoneNumber.replace(
        countryCode || "",
        "",
      );
      setDisplayPhoneNumber(numberWithoutCode);
    }
  }, [formData.phoneNumber, countryCode]);

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newCountryCode = event.target.value;
    setCountryCode(newCountryCode);
    setFormData((prevFormData) => ({
      ...prevFormData,
      countryCode: `${newCountryCode}`,
    }));
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newPhoneNumber = event.target.value;
    setDisplayPhoneNumber(newPhoneNumber);
    const field = "phoneNumber";
    const value = `${countryCode}${newPhoneNumber.trim()}`;
    setFormData((prevFormData) => ({
      ...prevFormData,
      phoneNumber: value,
    }));
    validateInputs({ field, value }, checkboxState);
  };

  const loadedPhoneNumber = useRef(false);

  return (
    <Box className="get-started__borrower-personal-data">
      <div className="get-started__wrapper">
        <Box
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
              margin: "0 auto",
              padding: 2,
              color: secondaryText,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CHBTextField
                  id="firstName"
                  label="First Name:"
                  disabledInput={true}
                  value={formData.firstName}
                  onChange={onValueChange}
                  error={
                    errors.find((err) => err.field === "firstName")?.message
                  }
                  hasError={Boolean(
                    errors.find((err) => err.field === "firstName"),
                  )}
                  animation={animation}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CHBTextField
                  id="lastName"
                  label="Last Name:"
                  value={formData.lastName}
                  onChange={onValueChange}
                  error={
                    errors.find((err) => err.field === "lastName")?.message
                  }
                  hasError={Boolean(
                    errors.find((err) => err.field === "lastName"),
                  )}
                  disabledInput={true}
                  animation={animation}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CHBTextField
                  id="middleName"
                  label="Middle Initial:"
                  value={formData.middleName}
                  onChange={onValueChange}
                  error={
                    errors.find((err) => err.field === "middleName")?.message
                  }
                  hasError={Boolean(
                    errors.find((err) => err.field === "middleName"),
                  )}
                  animation={animation}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CHBTextField
                  id="suffix"
                  label="Suffix:"
                  value={formData.suffix}
                  onChange={onValueChange}
                  error={errors.find((err) => err.field === "suffix")?.message}
                  hasError={Boolean(
                    errors.find((err) => err.field === "suffix"),
                  )}
                  animation={animation}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={sizes.typography}
                  marginBottom={"10px"}
                  className="custom-typography"
                  fontWeight={"bold"}
                  color={primaryText}
                >
                  Marital Status:
                </Typography>
                <select
                  id={"maritalStatus"}
                  onChange={onValueChange}
                  value={formData.maritalStatus}
                  style={{
                    border: errors.find((err) => err.field === "maritalStatus")
                      ? "2px solid red"
                      : `2px solid ${fourthText}`,
                    animation:
                      animation &&
                      errors.find((err) => err.field === "maritalStatus")
                        ? "shake 0.3s ease-in-out"
                        : "none",
                  }}
                >
                  <option value="Select" disabled style={{ display: "none" }}>
                    Select
                  </option>
                  <option value="Unmarried">Unmarried</option>
                  <option value="Married">Married</option>
                  <option value="Separated">Separated</option>
                </select>
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={"16px"}
                  color={"error"}
                  textAlign={"left"}
                  marginTop={"5px"}
                  className="custom-typography"
                  sx={{
                    animation:
                      animation &&
                      errors.find((err) => err.field === "maritalStatus")
                        ? "shake 0.3s ease-in-out"
                        : "none",
                  }}
                >
                  {error &&
                    errors.find((err) => err.field === "maritalStatus")
                      ?.message}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <CHBTextField
                  id="email"
                  label="Email Address:"
                  value={formData.email}
                  disabledInput={true}
                  onChange={onValueChange}
                  error={errors.find((err) => err.field === "email")?.message}
                  hasError={Boolean(
                    errors.find((err) => err.field === "email"),
                  )}
                  animation={animation}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={sizes.typography}
                  marginBottom={"10px"}
                  className="custom-typography"
                  fontWeight={"bold"}
                  color={primaryText}
                >
                  Country Code:
                </Typography>
                <select
                  id="country-code"
                  onChange={handleCountryChange}
                  value={countryCode}
                  style={{
                    height: "44.8px",
                    width: "100%",
                  }}
                >
                  <option value="" disabled style={{ display: "none" }}>
                    Country Code:
                  </option>
                  {Object.entries(CountryPhoneCode).map(
                    ([country, { id, name, code }]) => (
                      <option key={id} value={`${code}`}>
                        {name} ({code})
                      </option>
                    ),
                  )}
                </select>
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={"16px"}
                  color={"error"}
                  textAlign={"left"}
                  marginTop={"5px"}
                  className="custom-typography"
                ></Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={sizes.typography}
                  fontWeight={"bold"}
                  color={primaryText}
                  marginBottom={"10px"}
                  className="custom-typography"
                >
                  Mobile Phone:
                </Typography>
                <NumberFormat
                  className="get-started__phoneInput"
                  min={1}
                  id={"phoneNumber"}
                  name={"phoneNumber"}
                  onChange={handlePhoneNumberChange}
                  value={displayPhoneNumber}
                  format={
                    process.env.REACT_APP_ENV === "prod"
                      ? "##############"
                      : "##############"
                  }
                  allowEmptyFormatting
                  style={{
                    borderRadius: "5px",
                    border: errors.find((err) => err.field === "phoneNumber")
                      ? "2px solid red"
                      : `0px solid ${fourthText}`,
                    animation:
                      animation &&
                      errors.find((err) => err.field === "phoneNumber")
                        ? "shake 0.3s ease-in-out"
                        : "none",
                  }}
                  customInput={CustomInput}
                />
                <Typography
                  variant="body2"
                  fontFamily={"NunitoSans"}
                  fontSize={"16px"}
                  color={"error"}
                  textAlign={"left"}
                  marginTop={"5px"}
                  className="custom-typography"
                  sx={{
                    animation:
                      animation &&
                      errors.find((err) => err.field === "phoneNumber")
                        ? "shake 0.3s ease-in-out"
                        : "none",
                  }}
                >
                  {errors.find((err) => err.field === "phoneNumber")?.message}
                </Typography>
              </Grid>
              <p className="get-started__note" style={{ paddingTop: "10px" }}>
                <strong className="strong-700">
                  Why do we need your number?
                </strong>{" "}
                So we can send you SMS text alerts and follow up if we need
                additional information.
              </p>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <input
                  type="checkbox"
                  name="telemarketingConsentCheck"
                  className="get-started__checkbox"
                  checked={checkboxState.telemarketingConsentCheck}
                  onChange={handleCheckboxChange}
                  style={{
                    border:
                      error &&
                      errors.find(
                        (err) => err.field === "telemarketingConsentCheck",
                      )
                        ? "2px solid red"
                        : `0px solid ${fourthText}`,
                    animation:
                      animation &&
                      errors.find(
                        (err) => err.field === "telemarketingConsentCheck",
                      )
                        ? "shake 0.3s ease-in-out"
                        : "none",
                  }}
                />
                <Typography
                  fontSize={sizes.typography2}
                  color={primaryText}
                  sx={{
                    paddingLeft: "20px",
                    margin: "0px !important",
                    minWidth: "95%",
                    textAlign: "justify",
                  }}
                >
                  By checking this box and then clicking NEXT, you are providing
                  Homebridge Financial Services, Inc. (Homebridge) and its
                  service providers, with express written consent (OPT-IN) to
                  contact you at the email address and/or phone number you have
                  provided via text, automatic dialing system, artificial voice,
                  or pre-recorded messages used for telemarketing, even if your
                  phone number is registered on a Do Not Call Registry.
                  Homebridge does not share your OPT-IN with any third parties
                  for their own marketing purposes. You understand the frequency
                  of these communications may vary and you attest that you are
                  the subscriber /owner of the device’s account or have been
                  granted authority by the subscriber/owner of the account of
                  said device to consent to such contact. Data rates may apply.
                  Text STOP to opt out.
                </Typography>
              </Grid>
              {error &&
                errors.find(
                  (err) => err.field === "telemarketingConsentCheck",
                ) && (
                  <Typography
                    color="error"
                    align="center"
                    sx={{
                      fontSize: "16px",
                      mt: 2,
                      fontFamily: "NunitoSans",
                      animation: animation ? "shake 0.3s ease-in-out" : "none",
                    }}
                  >
                    {
                      errors.find(
                        (err) => err.field === "telemarketingConsentCheck",
                      )?.message
                    }
                  </Typography>
                )}
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                sm: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              marginTop: "40px",
            }}
          >
            <CHBButton
              onClick={onPrev}
              disabled={loading}
              secondaryArrow={true}
            >
              Go Back
            </CHBButton>
            <CHBButton
              onClick={handleNext}
              loading={loading}
              disabled={loading}
              className="animated-arrow-button"
              ctaId="Step9PersonalInfo"
            >
              Next
            </CHBButton>
          </Box>
        </Box>
        <HBSeparate className="step-separate" type="vertical" />
      </div>
    </Box>
  );
};
