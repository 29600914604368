import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme, Skeleton } from "@mui/material";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import dollarFormatter from "utils/FormatterDollar";
import Loan from "typedef/Loan";
import { PaydownInfo } from "typedef/ExperianCreditReport";
import { PayoffObject } from "typedef/Payoff";

interface ShowDetailsModalProps {
  openModal: boolean;
  onClose?: (event?: React.SyntheticEvent, reason?: string) => void;
  loan: Loan | undefined;
  paydowns: PaydownInfo | undefined;
  payoffs: PayoffObject[] | undefined;
}

const ShowNetDetailsModal: React.FC<ShowDetailsModalProps> = ({
  openModal,
  onClose,
  paydowns,
  payoffs,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const totalPaydowns =
    paydowns?.reduce((total, item) => total + Number(item.paydownAmount), 0) ??
    0;
  const totalPayoffs =
    payoffs?.reduce((total, item) => total + Number(item.amount), 0) ?? 0;
  const total = totalPaydowns + totalPayoffs;

  useEffect(() => {
    setLoading(!paydowns && !payoffs);
  }, [paydowns, payoffs]);

  return (
    <CDialog
      maxWidth="lg"
      open={openModal}
      PaperProps={{
        sx: {
          maxWidth: "800px",
          margin: "0 auto",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          textAlign: "center",
          width: "0 auto",
        }}
      >
        <Typography
          variant="h4"
          fontFamily="Pro Display Bold"
          width="100%"
          textAlign={"left"}
          marginBottom={1}
        >
          Paydowns / Payoffs
        </Typography>
        <Box
          sx={{
            justifyContent: "justify-between",
            display: "flex",
            gap: "60px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            style={{
              justifyContent: "justify-between",
              display: "flex",
              flexDirection: "column",
              flex: "1 1 0",
            }}
          >
            <Typography
              fontSize={18}
              color={theme.palette.text.primary}
              fontFamily="Pro Display Bold"
              sx={{ flex: "1 1 0", textAlign: "left", textWrap: "nowrap" }}
              marginBottom={1}
            >
              Paydowns:
            </Typography>
            {paydowns?.length ? (
              paydowns?.map((paydown, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  alignItems={"flex-start"}
                  sx={{ flex: "1 1 0" }}
                >
                  <Typography
                    fontSize={18}
                    color={theme.palette.text.primary}
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {paydown?.subscriberName}:
                  </Typography>
                  <Typography
                    color={theme.palette.text.primary}
                    fontFamily="Pro Display Regular"
                    ml={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      height: {
                        xs: "100%",
                        sm: "auto",
                      },
                    }}
                  >
                    {!loading ? (
                      dollarFormatter.format(Number(paydown?.paydownAmount))
                    ) : (
                      <Skeleton width="75%" height={"1.5rem"} />
                    )}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography
                fontSize={14}
                color={theme.palette.text.primary}
                fontFamily="Pro Display Regular"
              >
                No paydowns were found
              </Typography>
            )}

            <Typography
              fontSize={18}
              color={theme.palette.text.primary}
              fontFamily="Pro Display Bold"
              sx={{ flex: "1 1 0", textAlign: "left", textWrap: "nowrap" }}
              marginBottom={1}
              marginTop={2}
            >
              Payoffs:
            </Typography>
            {payoffs?.length ? (
              payoffs?.map(({ amount, name }) => (
                <Box
                  key={name}
                  display="flex"
                  justifyContent="space-between"
                  alignItems={"flex-start"}
                  sx={{ flex: "1 1 0" }}
                >
                  <Typography
                    fontSize={18}
                    color={theme.palette.text.primary}
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {name}:
                  </Typography>
                  <Typography
                    color={theme.palette.text.primary}
                    fontFamily="Pro Display Regular"
                    ml={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      height: {
                        xs: "100%",
                        sm: "auto",
                      },
                    }}
                  >
                    {!loading && dollarFormatter.format(Number(amount))}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography
                fontSize={14}
                color={theme.palette.text.primary}
                fontFamily="Pro Display Regular"
              >
                No payoffs were found
              </Typography>
            )}

            <Box
              sx={{
                display: "flex",
                textAlign: "left",
                textWrap: "nowrap",
                marginTop: "20px",
              }}
            >
              <Typography
                fontSize={18}
                color={theme.palette.text.primary}
                fontFamily="Pro Display Bold"
                sx={{ flex: "1 1 0", textAlign: "left", textWrap: "nowrap" }}
              >
                Total:
              </Typography>
              <Typography
                color={theme.palette.text.primary}
                fontFamily="Pro Display Bold"
                sx={{ flex: "1 1 0", textAlign: "right" }}
              >
                {Number(total) >= 0 ? (
                  dollarFormatter.format(Number(total))
                ) : (
                  <Skeleton
                    width="75%"
                    height={"1.5rem"}
                    sx={{ flex: "1 1 0", textAlign: "right" }}
                  />
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <CButton
          type="reset"
          variant="outlined"
          fullWidth
          sx={{
            marginTop: 2,
            backgroundColor: theme.palette.primary.main,
            color: "white",
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
          onClick={onClose}
        >
          Close Details
        </CButton>
      </Box>
    </CDialog>
  );
};

export default ShowNetDetailsModal;
