import "./styles.scss";
import { createPortal } from "react-dom";
import { Box, SxProps, Theme } from "@mui/material";
import { useIsHomebridge } from "utils/useIsHomebridge";

const modalRoot = document.getElementById("root");

interface ModalProps {
  fade?: boolean;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  sxModalChild?: SxProps<Theme>;
  sxModalChildChild?: SxProps<Theme>;
}

export const Modal = ({
  fade = false,
  open,
  onClose,
  children,
  sxModalChild,
  sxModalChildChild,
}: ModalProps) => {
  const { isHomeBridge } = useIsHomebridge();
  if (!open) return null;

  const modal = (
    <Box className={`custom-modal ${fade ? "custom-modal-fade" : ""}`}>
      {/* <Box className="custom-modal-overlay" onClick={() => onClose()} /> */}
      <Box
        className="custom-modal-child"
        sx={{
          width: "100%",
          maxWidth: "500px",
          ...sxModalChild,
        }}
      >
        <Box
          className={`custom-modal-child-child`}
          sx={{
            ...sxModalChildChild,
            ...(isHomeBridge && {
              backgroundColor: "#0a2631",
            }),
          }}
        >
          <Box>{children}</Box>
        </Box>
      </Box>
    </Box>
  );

  return createPortal(modal, modalRoot as HTMLElement);
};

export default Modal;
