import {
  LSAdminToken,
  LSAdminTokenExp,
  LSLoanOfficerToken,
  LSLoanOfficerTokenExp,
} from "CONST";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { UserType } from "typedef/ApiUserResponses";
import Loan from "typedef/Loan";
import { finalOfferValidations } from "utils/finalOfferValidations";

export const useAdminImpersonate = (
  loan?: Loan,
  isBorrower?: boolean,
  userAdmin?: UserType,
  loadingLoan?: boolean,
) => {
  const params = useParams();
  const loanId = params?.loanId;
  const adminToken = localStorage.getItem(LSAdminToken);
  const adminTokenExp = localStorage.getItem(LSAdminTokenExp);
  const loanOfficerToken = localStorage.getItem(LSLoanOfficerToken);
  const loanOfficerTokenExp = localStorage.getItem(LSLoanOfficerTokenExp);
  const [searchParams] = useSearchParams();
  const getAllLoans = searchParams.get("allLoans");

  const typeOfCreditLine =
    isBorrower && !loan?.borrowerFlags?.initialOfferAccepted;

  const finalOffer = loan ? finalOfferValidations(loan, undefined) : false;
  const borrowerTracker =
    isBorrower && loan?.borrowerSteps !== undefined && loan?.loanType;

  const coborrowerTracker = loan?.coborrowerSteps !== undefined;
  const homeMonitorValidations =
    loan?.borrowerFlags?.archiveType ||
    loan?.borrowerSteps?.fundingVerification?.status === "success";

  const DTCApplication = loan?.newLoanStatusCode?.startsWith("DTC");
  const [stateView, setStateView] = useState("");

  useEffect(() => {
    if (!loadingLoan) {
      if (userAdmin?.email && userAdmin?.id) {
        if (!loan?.id && !loan?.borrowerId) {
          setStateView("error");
          setMessageError(`Loan: #${loanId} not found.`);
        } else if (DTCApplication) {
          setStateView("DTCApplication");
        } else if (isBorrower && loan?.borrowerId?.includes("temp_borrower_")) {
          setStateView("error");
          setMessageError("The Borrower has not register yet.");
        } else if (
          !isBorrower &&
          loan?.coborrowerId?.includes("temp_coborrower_")
        ) {
          setStateView("error");
          setMessageError("The Co-Borrower has not register yet.");
        } else if (homeMonitorValidations) {
          setStateView("homeMonitor");
        } else if (typeOfCreditLine) {
          setStateView("typeOfCreditLine");
        } else if (borrowerTracker || coborrowerTracker) {
          setStateView("tracker");
        }

        if (finalOffer && borrowerTracker) {
          setStateView("finalOffer");
        }

        if (getAllLoans) {
          setStateView("allLoans");
        }
      }
    }
    if (
      !adminToken &&
      !adminTokenExp &&
      !loanOfficerToken &&
      !loanOfficerTokenExp
    ) {
      setStateView("error");
      setMessageError(`Session expired.`);
    }
  }, [
    loadingLoan,
    isBorrower,
    coborrowerTracker,
    borrowerTracker,
    userAdmin,
    finalOffer,
    typeOfCreditLine,
    loan,
    adminToken,
    adminTokenExp,
    loanId,
    loanOfficerToken,
    loanOfficerTokenExp,
    getAllLoans,
    homeMonitorValidations,
    DTCApplication,
  ]);

  const [messageForAdmin, setMessageForAdmin] = useState<string | null>(null);
  const [messageError, setMessageError] = useState<string | null>(null);

  const onClick = () => {
    setMessageForAdmin("No actions can be taken in this section.");
    setTimeout(() => {
      setMessageForAdmin(null);
    }, 3000);
  };
  return { stateView, messageError, messageForAdmin, onClick };
};
